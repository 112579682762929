import axios from 'axios';

import { filesToFormData } from 'containers/ClinicianRegister/api';

import { getLogger } from './logger';
const logger = getLogger('utils.api');

const headers = {
	'Content-Type': 'application/json',
};

const api = axios.create({
	headers,
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 60000,
});

// eslint-disable-next-line no-unused-vars
api.interceptors.request.use((config) => {
	const conf = config;
	conf.headers['x-access-token'] = localStorage.getItem('token');
	conf.headers['x-timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return conf;
});
api.interceptors.response.use(
	(response) => {
		if (response.status < 200 || response.status > 299) {
			throw Error(response.error);
		} else {
			return response;
		}
	},
	(error) => {
		if (error.response.data.error === 'jwt expired') {
			localStorage.clear();
			window.location = '/patient/signin';
		}
		throw error;
	},
);

export const getUserAddressApi = () =>
	api
		.get('/v2/patient/address')
		.then((response) => response.data)
		.catch((err) => err);

export const saveUserPaymentApi = (data) =>
	api
		.post('/v2/payment', data)
		.then((response) => response.data)
		.catch((err) => err);

export const pingServerApi = () =>
	api
		.get('/ping')
		.then((response) => response.data)
		.catch((err) => err);

export const patientRegistrationAPI = (data) =>
	api
		.put(`/patient/${data.id}/intake?q=${data.q}`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchAllCliniciansAPI = () =>
	api
		.get('/admin/all-clinicians')
		.then((response) => response)
		.catch((err) => err);

export const getProgramAPI = (data) =>
	api
		.get(`/program/find/${data.id}`)
		.then((response) => response)
		.catch((err) => err);

export const fetchRequestAPI = (data) =>
	api
		.get(`/request?id=${data.id}`)
		.then((response) => response)
		.catch((err) => err);

export const fetchAllClientsAPI = () =>
	api
		.get('/admin/all-clients')
		.then((response) => response)
		.catch((err) => err);

export const fetchPatientProfile = (id) =>
	api
		.get(`/patient/${id}/profile`)
		.then((response) => response.data)
		.catch((err) => err);

export const updatePatientAPI = (data) => {
	return api
		.put(`/patient/${data.id}`, data)
		.then((response) => response.data)
		.catch((err) => err);
};

export const deleteClinicianAPI = (data) =>
	api
		.put('/admin/delete-clinician', data)
		.then((response) => response)
		.catch((err) => err);

export const getClinicianAPI = (data) =>
	api
		.get(`/clinician/${data}`)
		.then((response) => response)
		.catch((err) => err);

export const changeClinicianAPI = (data) =>
	api
		.put('admin/change-clinician', data)
		.then((response) => response)
		.catch((err) => err);

export const editClinicianAPI = (data) =>
	api
		.put('/admin/edit-clinician', data)
		.then((response) => response)
		.catch((err) => err);

export const patientRegistrationFetchDataAPI = (data) =>
	api
		.get(`/patient/${data.id}/intake`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchDocumentListAPI = () =>
	api
		.get('/user/all-documents')
		.then((response) => response)
		.catch((err) => err);

export const findToDosAPI = (data) =>
	api
		.get(
			`/request/find-all?userId=${data.userId}&parentRequestValue=${data.parentRequest}`,
		)
		.then((response) => response)
		.catch((err) => err);

export const addNewClientAPI = (data) =>
	api
		.post('/clinician/add-new-client', data)
		.then((response) => response)
		.catch((err) => err);

export const fetchPatientGroupRequestsAPI = (data) =>
	api
		.get(`/patient/group-requests?isRx=${data}`)
		.then((response) => response.data)
		.catch((err) => err);

export const downloadFileAPI = (data) =>
	api
		.put(`/document/find?docId=${data.docId}`, null, {
			responseType: 'blob',
		})
		.then((response) => {
			let filename = response.headers['content-disposition']
				.split(';')
				.find((n) => n.includes('filename='))
				.replace('filename=', '')
				.replace(/"/g, '')
				.trim();
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => err);

export const findUser = (data) =>
	api
		.get(`/user/${data.id}`)
		.then((response) => response.data)
		.catch((err) => err);

export const assignClinicianAPI = (data) =>
	api
		.put('/admin/assign-clinician', data)
		.then((response) => response)
		.catch((err) => err);

export const changePharmacyAPI = (data) =>
	api
		.put('/admin/update-pharmacy', data)
		.then((response) => response)
		.catch((err) => err);

export const findPharmacy = (id) =>
	api
		.get(`/patient/pharmacy/${id}`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchGroupTypes = () =>
	api
		.get('/group-types/find-all')
		.then((response) => response.data)
		.catch((err) => err);

export const updateRequestAPI = (data) =>
	api
		.put(`/request/${data.id}`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const deleteRequestAPI = (data) => {
	return api
		.delete('/request', { data })
		.then((response) => response.data)
		.catch((err) => err);
};

export const createRequestAPI = (data) =>
	api
		.post('/request', data)
		.then((response) => response)
		.catch((err) => err);

export const fetchClinicians = () =>
	api
		.get('/clinician/find-all')
		.then((response) => response.data)
		.catch((err) => err);

export const createProgramAndSession = (data) =>
	api
		.post('/program/create', data)
		.then((response) => response)
		.catch((err) => err);

export const updateProgramAndSession = (data) =>
	api
		.post('/program/update', data)
		.then((response) => response)
		.catch((err) => err);

export const deleteProgramAPI = (data) =>
	api
		.put('/program/delete', data)
		.then((response) => response)
		.catch((err) => err);

export const deactivateProgramAPI = (data) =>
	api
		.put('/program/deactivate', data)
		.then((response) => response)
		.catch((err) => err);

export const sendSurveyAPI = (data) =>
	api
		.post(`/program/${data.programId}/send-survey`, data)
		.then((response) => response)
		.catch((err) => err);

export const fetchPrograms = () =>
	api
		.get('/program/find-all')
		.then((response) => response.data)
		.catch((err) => err);

export const fetchProgramsPrivate = () =>
	api
		.get('/program/find-all-public')
		.then((response) => response.data)
		.catch((err) => err);

export const fetchMyGroupsAPI = () =>
	api
		.get('/group-types/my-groups')
		.then((response) => response.data)
		.catch((err) => err);

export const fetchAllGroupsAPI = (data = {}) =>
	api
		.get(`/program/all-groups${data.id ? `?id=${data.id}` : ''}`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchPatientPrograms = (data) =>
	api
		.get(`/patient/${data.id}/programs?enrolled=${data.enrolled}`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchPatientDocs = (id) =>
	api
		.get(`/patient/${id}/docs`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchStatesAPI = () => api.get('/geo/states');
export const fetchCitiesAPI = (data) =>
	api.get(`/geo/cities?stateCode=${data}`);
export const fetchZipcodesAPI = (data) =>
	api.get(`/geo/zipcode?cityName=${data}`);

export const fetchPatientAttendanceAPI = (id) =>
	api
		.get(`/patient/${id}/attendance`)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchAttendanceAPI = (data) => {
	return api
		.get(
			`/attendance?programId=${data.programId}&month=${data.month}&year=${
				data.year
			}&patientId=${data.patientId || 0}`,
		)
		.then((response) => response.data)
		.catch((err) => err);
};

export const updateAttendanceAPI = (data) => api.put('/attendance', data);
export const saveAttendanceAPI = (data) => api.post('/attendance', data);
export const deleteAttendanceAPI = (data) =>
	api
		.put('/attendance/delete', data)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchSessionsAPI = (data) =>
	api
		.get(`/session?programId=${data.programId}&patientId=${data.patientId}`)
		.then((response) => response.data)
		.catch((err) => err);

export const addSessionAPI = (data) =>
	api
		.post(
			`/session/create?programId=${data.programId}&clinicianId=${data.clinicianId}&startDate=${data.startDate}&endDate=${data.endDate}`,
		)
		.then((response) => response.data)
		.catch((err) => err);

export const extendSessionsAPI = (data) =>
	api
		.post('/session/extend', data)
		.then((response) => response.data)
		.catch((err) => err);

export const deleteSessionsAPI = (ids) =>
	api
		.delete('/session/delete', { data: ids })
		.then((response) => response.data)
		.catch((err) => err);

export const fetchMyClientsAPI = () =>
	api
		.get('clinician/my-clients')
		.then((response) => response)
		.catch((err) => err);

export const attendanceReport = (data) =>
	api
		.get(`/attendance/report?month=${data.month}&year=${data.year}`, {
			responseType: 'blob',
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`Attendance_Report_${data.month}_${data.year}.csv`,
			);
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => err);

export const doMagicAPI = (data) =>
	api
		.post('admin/do-magic', data)
		.then((response) => response)
		.catch((err) => err);

export const fetchClientsAPI = (data) =>
	api
		.get(`program/${data.programId}/my-clients`)
		.then((response) => response)
		.catch((err) => err);

export const roiAPI = (data) =>
	api
		.post(`/patient/${data.senderUserId}/roi`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const questionnaireAPI = (data) =>
	api
		.post('/test/questionnaire', data)
		.then((response) => {
			let filename = response.headers['content-disposition']
				.split(';')
				.find((n) => n.includes('filename='))
				.replace('filename=', '')
				.replace(/"/g, '')
				.trim();
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => err);

export const telehealthAPI = (data) =>
	api
		.post(`/patient/${data.senderUserId}/telehealth`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const signDocAPI = (id) =>
	api
		.post(`/patient/${id}/sign-document`)
		.then((response) => response.data)
		.catch((err) => err);

export const enrollmentRequestAPI = (data) =>
	api
		.post(`/patient/${data.senderUserId}/enrollment-request`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const updateEnrollmentApi = (data) =>
	api
		.post('/program/update-enrollment', data)
		.then((response) => response)
		.catch((err) => err);

export const uploadPDF = (data) =>
	api
		.post('/signnow/upload', data)
		.then((response) => response)
		.catch((err) => err);

export const feedbackApi = (data) =>
	api
		.post('/feedback', data)
		.then((response) => response)
		.catch((err) => err);

export const fetchFeedbackApi = () =>
	api
		.get('/feedback')
		.then((response) => response)
		.catch((err) => err);

export const updateFeedbackApi = (id) =>
	api
		.post(`/feedback/${id}`)
		.then((response) => response)
		.catch((err) => err);

export const docLogAPI = () =>
	api
		.get('/admin/document-log')
		.then((response) => response.data)
		.catch((err) => err);

export const uploadFormsAPI = (data) => {
	const formData = filesToFormData(data.files);

	return api
		.post('/document/upload', formData)
		.then((response) => response)
		.catch((err) => err);
};

export const fetchFormsAPI = (data = {}) =>
	api
		.get(`/forms${data.id ? `?requestId=${data.id}` : ''}`)
		.then((response) => response)
		.catch((err) => err);

export const declineFormAPI = (data) =>
	api
		.post('/forms/decline', data)
		.then((response) => response)
		.catch((err) => err);

export const updateFormAPI = (data) => {
	return api
		.post('/forms', data)
		.then((response) => response)
		.catch((err) => err);
};

export const deleteFormAPI = (data) =>
	api
		.delete(`/forms?uuid=${data.uuid}`)
		.then((response) => response)
		.catch((err) => err);

export const createSigningDocAPI = (data) =>
	api
		.post('/forms/share', data)
		.then((response) => response)
		.catch((err) => err);

export const downloadFormAPI = ({ uuid }) =>
	api
		.get(`/forms/share?uuid=${uuid}`)
		.then((response) => response)
		.catch((err) => err);

export const fetchNotificationsAPI = () =>
	api
		.get('/notifications')
		.then((response) => response)
		.catch((err) => err);

export const updateNotificationsAPI = (notificationIds) =>
	api
		.put('/notifications', { notificationIds })
		.then((response) => response)
		.catch((err) => err);

export const submitFormAPI = (data) =>
	api
		.post('/forms/return', data)
		.then((response) => response)
		.catch((err) => err);

export const getPermissionsAPI = () =>
	api
		.get('/permissions')
		.then((response) => response)
		.catch((err) => err);

export const createOQEmployeeAPI = (data) => 
	api
		.post('/v2/analyst/create-employee', data)
		.then((response) => response)
		.catch((err) => err);

export const createOQClientAPI = (data) => 
	api
		.post('/v2/analyst/create-client', data)
		.then((response) => response)
		.catch((err) => err);

export const getOQProfileAPI = (data) => 
	api
		.post('/v2/analyst/get-profile', data)
		.then((response) => response)
		.catch((err) => err);

export const getOQClientAPI = (data) => 
	api
		.post('/v2/analyst/get-client', data)
		.then((response) => response)
		.catch((err) => err);

export const getOQAdministrationURLAPI = (data) => {
	logger.log('getOQAdministrationURLAPI');
	return api
		.post('/v2/analyst/get-url', data)
		.then((response) => response)
		.catch((err) => err);
};

export const sendOQAdministrationSMSAPI = (data) => {
	logger.log('sendOQAdministrationSMSAPI');
	return api
		.post('/v2/analyst/send-sms', data)
		.then((response) => response)
		.catch((err) => err);
};

export default api;
