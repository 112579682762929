import React from 'react';

import styled from 'styled-components';


const Card = styled.div`
	border: 1px solid var(--main-grey);
	padding: 1rem 0.75rem;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const TextContent = styled.div`
	padding-top: 0.375rem;
	display: flex;
	flex-direction: column;
`;

const PatientRegistrationType = (props) => {
	const {
		handleChange,
		nextStep,
	} = props;

	const handleClick = (component) => (event) => {
		handleChange(component)(event);
		nextStep(event.value);
	};

	return (
		<div className="py-2">
			<h4 className="text-center mb-4">
				Please select your registration type:
			</h4>
			<div className="row w-100 mx-0 pb-4">
				<div className="col-12">
					<Card className="btn-block pt-2" >
							<TextContent>
								<h4>Outpatient Adult</h4>
								<p>
									Are you an adult (18+) registering to set up individual appointments?
								</p>
							</TextContent>
							<button
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-weight-bold font-15 w-25"
								onClick={handleClick('patientDetails')}
								name="registrationType"
								value="adult"
							>
								Start
							</button>
					</Card>
				</div>
			</div>
			<div className="row w-100 mx-0 pb-4">
				<div className="col-12">
					<Card className="btn-block pt-2" >
							<TextContent>
								<h4>IOP or PHP</h4>
								<p>
									Are you registering for our INTENSIVE PROGRAM?
								</p>
							</TextContent>
							<button
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-weight-bold font-15 w-25"
								onClick={handleClick('patientDetails')}
								name="registrationType"
								value="iopPhp"
							>
								Start
							</button>
					</Card>
				</div>
			</div>
			<div className="row w-100 mx-0 pb-4">
				<div className="col-12">
					<Card className="btn-block" >
						<TextContent>
							<h4>Teen/Child</h4>
							<p>
								Are you registering your teen or child for outpatient care?
							</p>
						</TextContent>
						<button
							type="button"
							className="btn btn-primary btn-color btn-lg btn-block font-weight-bold font-15 w-25"
							onClick={handleClick('patientDetails')}
							name="registrationType"
							value="child"
						>
							Start
						</button>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default PatientRegistrationType;
