import { startCase } from 'lodash';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const SendSurveyModal = (props) => {
    const { isOpen, toggle, client, onSubmit } = props;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                className="modal-dialog-centered"
                backdrop="static"
                size="md"
            >
                <ModalHeader
                    toggle={toggle}
                    className="align-center border-0 container-fluid"
					cssModule={{
						'modal-title':
							'd-flex justify-content-center w-100 pt-2 text-dark font-weight-bold',
					}}
                >
                    <div style={{ fontSize: '28px' }}>
						{`Send Survey to ${startCase(client.name)}`}
					</div>
                </ModalHeader>
                <ModalBody className="py-0">
					<div className="row">
						<div className="container-fluid bg-white mx-4 mb-4 pt-3">
                            Survey will be sent to: {client.phone}
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center p-4">
                        <button
                            onClick={toggle}
                            className="btn btn-white link-color border btn-lg text-uppercase font-13 col-4 d-flex justify-content-center"
                        >
                            <span className="mr-0 w-100">CANCEL</span>
                        </button>
                        <div className="col-sm-1"></div>
                        <button
                            onClick={onSubmit}
                            className="btn btn-primary btn-lg btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
                        >
                            <span className="mr-0 w-100">SUBMIT</span>
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SendSurveyModal;