import { Formik } from 'formik';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import ToggleSwitch from 'atoms/ToggleSwitch';
import IAccept from 'components/IAccept';
import LoadingIndicator from 'components/LoadingIndicator';
import RequestSentModal from 'components/RequestSentModal';
import useIsMobile from 'hooks/isMobile.ts';
import { saveUserPaymentApi } from 'utils/api';
import { isProduction } from 'utils/constants';
import Input from 'v2/forms/Input';

import PaymentForm from './PaymentForm';
import {
	GuarantorValidation,
	PatientPaymentValidation,
	initialPaymentValues
} from './constants';

export default function PatientPaymentIntake(props) {
	const {
		guarantorChecked,
		patientPaymentDetailsChecked: ppdChecked,
		patientPaymentAgreementChecked: ppaChecked,
		handleAcceptance,
		auth,
	} = props;

	const intakeData = useSelector((state) => state.get('patientIntake').toJS());
	const hasVaultId = Boolean(intakeData.vaultId);

	const [useVaultInformation, setUseVaultInformation] = useState(hasVaultId);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [useGuarantor, setUseGuarantor] = useState(false);

	const { name } = auth?.loggedInUser ?? {};

	const isMobile = useIsMobile();

	async function saveUserPaymentInfo(formValues) {
		const backendResp = await saveUserPaymentApi({
			...formValues,
		});
		setIsSubmitting(false);

		if (backendResp.success) {
			nextProgress();
		} else {
			setSubmitError(
				'Payment information could not be validated. Please contact an administrator',
			);
		}
	}

	function toggleUseVaultInformation() {
		setUseVaultInformation(!useVaultInformation);
	}

	function nextProgress(values) {
		const data = {};
		data.checks = {
			patientPaymentDetailsChecked: ppdChecked,
			patientPaymentAgreementChecked: ppaChecked,
		};

		data.guarantor = useGuarantor ? values.guarantor : {};

		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();

		props.nextProgress(data)();
	}

	function onSubmit(values) {
		if (useVaultInformation || useGuarantor) {
			nextProgress(values);
			return;
		}

		setIsSubmitting(true);
		window.CollectJS.startPaymentRequest();
	}

	const agreementInformation = (
		<div className="card d-flex flex-column pt-2 pb-4 px-4 bb-0">
			<span className="pt-3 pb-4">
				I acknowledge that Mind Therapy Clinic does not accept medical
				insurance. Therefore I choose to contract directly with Mind Therapy
				Clinic for psychiatric care.
			</span>
			<span className="pt-1 pb-4">
				I understand that I may request a receipt of services for my psychiatric
				care from Mind Therapy Clinic, which I may submit for reimbursement from
				my insurance carrier, but I further understand that my payment to Mind
				Therapy Clinic is not dependent upon my receiving reimbursement from my
				insurance carrier.
			</span>
			<span className="pt-1 pb-2">
				I understand that I must create an account with the medical office by
				submitting a credit card number prior to the scheduling of my next
				appointment.
				<strong>
					{' '}
					I also understand that payment for all medical services is due at the
					time that they are rendered and will be charged to this credit card. I
					further understand that appointments that have not been cancelled in
					sufficient time (as per the Cancellation Policy) will be charged to
					this credit card.
				</strong>
			</span>
			<IAccept
				handleAcceptance={handleAcceptance}
				name="patientPaymentDetailsChecked"
				value={ppdChecked}
				isRequired
			/>
		</div>
	);

	const agreementTwo = (
		<div className="card d-flex flex-column p-4 mb-4 bt-0">
			<span className="pt-2 pb-4">
				<strong>
					Financially responsible party must either provide a credit card to
					keep on file OR pay at the time of the visit.
				</strong>
				<span> </span> I, financially responsible party, agree to keep the
				credit card information current. authorize Mind Therapy Clinic to
				securely store my credit card information, and charge it should I have
				an outstanding balance in the future. I understand that I may be given a
				receipt of services at my request, and that I may submit for
				reimbursement from my insurance carrier but that reimbursement is not
				guaranteed by Mind Therapy Clinic nor is my responsibility to pay for
				services dependent upon insurance reimbursement.
			</span>
			<span className="pt-1 pb-5">
				I hereby acknowledge that I have read, understand, and agree to this
				PATIENT PAYMENT AGREEMENT with Mind Therapy Clinic.
			</span>
			<IAccept
				handleAcceptance={handleAcceptance}
				name="patientPaymentAgreementChecked"
				value={ppaChecked}
				isRequired
			/>
		</div>
	);

	const guarantorCheck = (
		<div className="card d-flex flex-column p-4">
			<div>Please choose one of the following:</div>
			<div className="row mx-0 py-2">
				<div className="col-auto">
					<input
						type="checkbox"
						className="hand"
						id="noGuarantor"
						onChange={() => setUseGuarantor(false)}
						checked={!useGuarantor}
						value={!useGuarantor}
						name="noGuarantor"
					/>
				</div>
				<div>
					<label className="font-15" htmlFor="noGuarantor">
						I, {name}, am financially responsible for the treatment.
					</label>
				</div>
			</div>
			<div className={`row mx-0 pb-${guarantorChecked ? 2 : 4}`}>
				<div className="col-auto">
					<input
						type="checkbox"
						className="hand"
						id="guarantorChecked"
						onChange={() => setUseGuarantor(true)}
						checked={useGuarantor}
						value={useGuarantor}
						name="guarantorChecked"
					/>
				</div>
				<div>
					<label className="font-15" htmlFor="guarantorChecked">
						There is a guarantor or other financially responsible party.
					</label>
				</div>
			</div>
			{useGuarantor && (
				<>
					<div className="form-row align-items-center">
						<div className="col-md-4">
							<Input
								label="FINANCIALLY RESPONSIBLE PARTY NAME*"
								id="guarantor.name"
								name="guarantor.name"
								placeholder="John Doe"
							/>
						</div>
						<div className="col-md-4">
							<Input
								label="FINANCIALLY RESPONSIBLE PARTY EMAIL*"
								id="guarantor.email"
								name="guarantor.email"
								placeholder="johndoe@gmail.com"
							/>
						</div>
					</div>
					<div className="pb-4 font-italic">
						Mind Therapy Clinic will send a separate Financial Agreement to the
						party listed above.
					</div>
				</>
			)}
		</div>
	);

	return (
		<>
			<LoadingIndicator
				text="Verifying payment information..."
				isLoading={isSubmitting}
			/>
			<Formik
				initialValues={initialPaymentValues}
				onSubmit={onSubmit}
				validationSchema={ useGuarantor ? GuarantorValidation : PatientPaymentValidation}
				validateOnMount
			>
				{({ handleSubmit, isValid, values }) => (
					<div
						className={`container-fluid d-flex flex-column px-${
							isMobile ? 0 : 5
						}`}
					>
						<h2 className="align-self-center font-weight-bold mb-5 mt-5">
							Patient Payment Agreement
						</h2>
						{agreementInformation}
						{guarantorCheck}
						{hasVaultId && (
							<div
								className={`card d-flex flex-column p-4 pt-0 ${
									!useVaultInformation ? 'bb-0' : ''
								}`}
							>
								<div className="row mt-2">
									<div className="col-auto pr-0">
										<ToggleSwitch
											onClick={toggleUseVaultInformation}
											selected={useVaultInformation}
											className="mt-1"
										/>
									</div>
									<div className="col-auto">
										Use previously submitted payment information.
									</div>
								</div>
								{useVaultInformation && (
									<div>
										For your privacy and safety your previously submitted
										payment information has been hidden.
									</div>
								)}
							</div>
						)}
						{!useVaultInformation && !useGuarantor && (
							<PaymentForm
								onSaveToken={saveUserPaymentInfo}
								defaultAddress={intakeData.address}
							/>
						)}
						{agreementTwo}
						<div className="d-flex mt-4 mb-5 justify-content-center">
							<div className="col-6">
								<PrevButton onClick={props.prevProgress} isMobile={isMobile} />
							</div>
							<div className="col-6">
								<NextButton
									isMobile={isMobile}
									onClick={() => {
										useVaultInformation ? onSubmit(values) : handleSubmit();
									}}
									disabled={(!useVaultInformation && !isValid)}
								/>
							</div>
						</div>
					</div>
				)}
			</Formik>
			{submitError && (
				<RequestSentModal
					message={submitError}
					hide={() => setSubmitError('')}
				/>
			)}
		</>
	);
}
