import { call, put, takeLatest } from 'redux-saga/effects';

import {
	addNewClientAPI,
	addSessionAPI,
	deleteSessionsAPI,
	extendSessionsAPI,
	fetchAllClientsAPI,
	fetchAttendanceAPI,
	fetchClientsAPI,
	fetchMyClientsAPI,
	fetchSessionsAPI,
	getProgramAPI,
	saveAttendanceAPI,
	updateEnrollmentApi,
	sendSurveyAPI,
} from 'utils/api';
import { noop } from 'utils/common';

import {
	addClientError,
	addClientSuccess,
	addSessionError,
	addSessionSuccess,
	deleteSessionsError,
	deleteSessionsSuccess,
	extendSessionsError,
	extendSessionsSuccess,
	fetchAllClientsError,
	fetchAllClientsSuccess,
	getAttendanceError,
	getAttendanceSuccess,
	getClientsError,
	getClientsSuccess,
	getSessionsError,
	getSessionsSuccess,
	loadProgramError,
	loadProgramSuccess,
	saveAttendanceError,
	saveAttendanceSuccess,
	updateEnrollmentError,
	updateEnrollmentSuccess,
} from './actions';
import {
	ADD_CLIENT,
	ADD_SESSION,
	DELETE_SESSIONS,
	EXTEND_SESSIONS,
	FETCH_ALL_CLIENTS,
	GET_ATTENDANCE,
	GET_CLIENTS,
	GET_SESSIONS,
	LOAD_PROGRAM,
	SAVE_ATTENDANCE,
	UPDATE_ENROLLMENT,
	SEND_SURVEY,
} from './constants';

export default function* groupDetailSaga() {
	yield takeLatest(GET_ATTENDANCE, getAttendance);
	yield takeLatest(ADD_SESSION, addNewSession);
	yield takeLatest(DELETE_SESSIONS, deleteSessions);
	yield takeLatest(SAVE_ATTENDANCE, saveAttendance);
	yield takeLatest(LOAD_PROGRAM, getProgram);
	yield takeLatest(GET_SESSIONS, getSessions);
	yield takeLatest(GET_CLIENTS, getClients);
	yield takeLatest(UPDATE_ENROLLMENT, updateEnrollment);
	yield takeLatest(FETCH_ALL_CLIENTS, fetchAllClients);
	yield takeLatest(ADD_CLIENT, addClient);
	yield takeLatest(EXTEND_SESSIONS, extendSessions);
	yield takeLatest(SEND_SURVEY, sendSurvey);
	// See example in containers/HomePage/saga.js
}

export function* addClient(actions) {
	const { data, cb } = actions;
	try {
		const response = yield call(addNewClientAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(addClientError(response.response.data));
		} else {
			yield put(addClientSuccess(response.data.data));
			if (cb) {
				cb();
			}
		}
	} catch (err) {
		yield put(addClientError(err));
	}
}

export function* fetchAllClients(action) {
	try {
		const { role } = action;
		const api = role === 'admin' ? fetchAllClientsAPI : fetchMyClientsAPI;
		const response = yield call(api);

		if (response.response && response.response.status >= 400) {
			yield put(fetchAllClientsError());
		} else {
			yield put(fetchAllClientsSuccess(response.data));
		}
	} catch (err) {
		yield put(fetchAllClientsError(err));
	}
}

export function* getAttendance(actions) {
	const { data, cb = noop } = actions;
	try {
		const response = yield call(fetchAttendanceAPI, data);
		cb();
		yield put(getAttendanceSuccess(response));
	} catch (err) {
		yield put(getAttendanceError(err));
	}
}

export function* getProgram(actions) {
	try {
		const { data } = actions;
		const response = yield call(getProgramAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(loadProgramError());
		} else {
			yield put(loadProgramSuccess(response.data[0]));
		}
	} catch (err) {
		yield put(loadProgramError(err));
	}
}

export function* saveAttendance(actions) {
	const { data, res, errCB } = actions;
	try {
		const response = yield call(saveAttendanceAPI, data);
		yield put(saveAttendanceSuccess(response.data));
		if (res) {
			res();
		}
	} catch (err) {
		yield put(saveAttendanceError(err));
		if (errCB) {
			errCB();
		}
	}
}

export function* getSessions(actions) {
	const { data } = actions;
	try {
		const response = yield call(fetchSessionsAPI, data);
		yield put(getSessionsSuccess(response));
	} catch (err) {
		yield put(getSessionsError(err));
	}
}

export function* getClients(actions) {
	const { data } = actions;
	try {
		const response = yield call(fetchClientsAPI, data);
		yield put(getClientsSuccess(response.data));
	} catch (err) {
		yield put(getClientsError(err));
	}
}

/* export function* unenrollClient(actions) {
  const { data } = actions;
  try {
    const response = yield call(unenrollClientApi, data);
    if (response.response) {
      yield put(unenrollSuccess(response.response.data));
    } else {
      yield put(unenrollSuccess(response.data));
    }
  } catch (err) {
    yield put(unenrollError(err));
  }
} */

export function* updateEnrollment(actions) {
	const { data } = actions;
	try {
		const response = yield call(updateEnrollmentApi, data);
		yield put(updateEnrollmentSuccess(response.data));
	} catch (err) {
		yield put(updateEnrollmentError(err));
	}
}

export function* addNewSession(actions) {
	const { data } = actions;
	try {
		const response = yield call(addSessionAPI, data);
		yield put(addSessionSuccess(response));
	} catch (err) {
		yield put(addSessionError(err));
	}
}

export function* extendSessions(action) {
	const { data, cb } = action;
	try {
		const response = yield call(extendSessionsAPI, data);
		yield put(extendSessionsSuccess({ newSessions: response.newSessions }));
		cb(response.newSessions);
	} catch (err) {
		yield put(extendSessionsError(err));
		cb();
	}
}

export function* deleteSessions(actions) {
	const { data } = actions;
	try {
		const response = yield call(deleteSessionsAPI, data);
		yield put(deleteSessionsSuccess(response));
	} catch (err) {
		yield put(deleteSessionsError(err));
	}
}

export function* sendSurvey(action) {
	const { data, successCB = noop, errCB = noop } = action;
	try {
		const response = yield call(sendSurveyAPI, data);
		if(response.response && response.response.status >= 400) {
			throw new Error('sendSurveyError', response.response.data.error);
		}
		successCB();
	} catch (err) {
		errCB(err.message ?? 'Error Sending SMS');
	}
}